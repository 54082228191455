<template>
    <section class="pa-4 formular">
        <!--SAVE BUTTON TOOLBAR-->
        <div class="toolbar-buttons">
            <v-btn v-if="checkPrivileg && edit" dark small depressed text @click="submit('saveItem')">
                <v-icon left>mdi-content-save-outline</v-icon>
                <span>{{ $t('common.save') }}</span>
            </v-btn>
        </div>

        <span v-if="false">{{card_item}}</span>
        <span v-if="false">{{personal_web}}</span>
        <v-container fluid>

            <v-row :class="{ 'rgg-grey' : this.$vuetify.theme.dark }" class="grey lighten-4 rounded mb-2">
                <v-col cols="12" class="headline gold--text text-uppercase font-weight-light pb-0">
                    {{card_item.seller}}
                </v-col>
                <v-col cols="12" class="headline white--text text-uppercase font-weight-medium">
                    <span class="tag-status white--text rounded px-4 mr-1" :class="card_item.state">{{card_item.paid}}</span>
                    <span class="gold rounded px-4 mr-1">{{card_item.duration}}</span>

                </v-col>

                <v-col v-bind="col_default">
                    <v-dialog
                        ref="web_expired"
                        v-model="picker_expired"
                        :return-value.sync="personal_web.validation"
                        persistent
                        width="290px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="personal_web.validity"
                                :label="$t('products_personal_webs.dialog.expire')"
                                prepend-inner-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                outlined dense
                                color="gold"
                            >
                                <template v-if="false" v-slot:prepend>
                                    <v-icon x-small color="red">mdi-asterisk</v-icon>
                                </template>
                            </v-text-field>
                        </template>
                        <v-date-picker
                            v-model="personal_web.validity"
                            scrollable
                            :first-day-of-week="1"
                            locale="sk"
                            color="gold"
                        >
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="gold"
                                @click="picker_expired = false"
                            >
                                {{ $t('common.cancel') }}
                            </v-btn>
                            <v-btn
                                text
                                color="gold"
                                @click="$refs.web_expired.save(personal_web.validity);"
                            >
                                {{ $t('common.ok') }}
                            </v-btn>
                        </v-date-picker>
                    </v-dialog>
                </v-col>
                <v-col v-bind="col_default">
                    <v-checkbox class="pt-0 mt-2"
                                v-model="personal_web.lifetime"
                                :label="$t('products_personal_webs.dialog.unlimited')"
                                hide-details
                    />
                </v-col>
            </v-row>
        </v-container>
    </section>

</template>

<script>
import AuthMixin from "@/services/auth/auth_mixin";
import Permissions from "@/services/auth/permissions";
import {updatePersonalWeb} from "@/api/personal_webs";

export default {
    name: "PersonalWebDialog",
    props: {
        edit: Boolean,
        id: undefined,
        card_item: undefined,
    },
    data: () => ({
        picker_expired: false,
        personal_web: {
            validDate: undefined,
            lifetime: undefined
        },

        col_default: {cols: 12, sm: 6, md: 4, lg: 3}
    }),
    mixins: [AuthMixin],

    watch: {
        '$vuetify.breakpoint.width': function () {
            this.$parent.$emit('action', {func: 'setToolbarTitle', data: this.toolbar_title})
        },
    },

    created() {
        this.$parent.$emit('action', {func: 'setToolbarTitle', data: this.toolbar_title});
        this.personal_web.validity = this.card_item.raw_data.validDate;
        this.personal_web.lifetime = this.card_item.raw_data.lifetime;
    },

    updated() {
        this.$parent.$emit('action', {func: 'setToolbarTitle', data: this.toolbar_title});
    },

    computed: {
        toolbar_title() {
            if (this.edit) {
                if (this.$vuetify.breakpoint.width < 451) {
                    return this.id;
                } else {
                    return this.id + ' • ' + this.$t('toolbar_title_new.personal_web_detail')
                }
            } else {
                return this.edit;
            }
        },

        checkPrivileg() {
            if (this.checkPermission(Permissions.ADMINISTRATOR)) {
                return true;
            }

            return !this.edit;
        }
    },
    methods: {
        toFunc(e) {
            this[e.func](e.data);
        },
        update() {
            this.$root.$emit('overlay', true);
            let data = {};
            data.validDate = this.personal_web.validity;
            data.lifetime = this.personal_web.lifetime;

            updatePersonalWeb(this.id, data).then(() => {
                this.$root.$emit('overlay', false);
            })
        }
    }
}
</script>